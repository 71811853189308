import React from "react";
import Header from "./Header";

export default function blogs() {
  return (
    <>
      <Header />

      <link
        rel="stylesheet"
        href="https://demos.creative-tim.com/notus-js/assets/styles/tailwind.css"
      ></link>
      <link
        rel="stylesheet"
        href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"
      ></link>

      <section class="relative pt-16 bg-blueGray-50">
        <div class="container mx-auto">
          <div class="flex flex-wrap items-center">
            <div class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-78">
              <div class="relative flex flex-col min-w-0 break-words bg-green-700 w-full mb-6 shadow-lg rounded-lg bg-green-500">
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=700&amp;q=80"
                  class="w-full align-middle rounded-t-lg"
                ></img>
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-green-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Certificateur de compétences
                  </h4>
                  <p class="text-md font-light mt-2 text-white  text-justify">
                    CINEF SAS a plus de 10 ans d'expériences dans le
                    renforcement de capacités des personnels d'entreprises
                    privées et publiques. Nos certificats sont reconnus et
                    appréciés par les entreprises qui cherchent à employer des
                    compétences qualifiés.
                  </p>
                </blockquote>
              </div>
            </div>

            <div class="w-full md:w-6/12 px-4">
              <div class="flex flex-wrap">
                <div class="w-full md:w-6/12 px-4">
                  <div class="relative flex flex-col mt-4">
                    <div class="px-4 py-5 flex-auto">
                      <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-700">
                        <i class="fas fa-sitemap"></i>
                      </div>
                      <h6 class="text-xl mb-1 font-semibold">Formations +</h6>
                      <p class="mb-4 text-blueGray-500 text-justify ">
                        Documentez et validez votre compétence en obtenant une
                        certification CINEF !
                      </p>
                    </div>
                  </div>
                  <div class="relative flex flex-col min-w-0">
                    <div class="px-4 py-5 flex-auto">
                      <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-700">
                        <i class="fas fa-drafting-compass"></i>
                      </div>
                      <h6 class="text-xl mb-1 font-semibold">Conseils +</h6>
                      <p class="mb-4 text-blueGray-500 text-justify">
                        Nous sommes à vos côtés et nous nous engageons avec
                        vous. Nous transformons vos visions en réalités.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-6/12 px-4">
                  <div class="relative flex flex-col min-w-0 mt-4">
                    <div class="px-4 py-5 flex-auto">
                      <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-700">
                        <i class="fas fa-newspaper"></i>
                      </div>
                      <h6 class="text-xl mb-1 font-semibold">Rh +</h6>
                      <p class="mb-4 text-blueGray-500 text-justify">
                        Le Robot Isabelle de CINEF est un chasseur de tête en
                        plus de renforcer les compétences.
                      </p>
                    </div>
                  </div>
                  <div class="relative flex flex-col min-w-0">
                    <div class="px-4 py-5 flex-auto">
                      <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-700">
                        <i class="fas fa-file-alt"></i>
                      </div>
                      <h6 class="text-xl mb-1 font-semibold">Numérique +</h6>
                      <p class="mb-4 text-blueGray-500 text-justify">
                        Nous vous accompagnons dans l'innovation de vos outils
                        de ventes de services ou produits. Vous touchez le futur
                        aujourd'hui.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="relative bg-blueGray-50 pt-8 pb-6 mt-2">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap items-center md:justify-between justify-center">
              <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                <div class="text-sm text-blueGray-500 font-semibold py-1">
                  &copy; CopyRight@{" "}
                  <a
                    href="https://www.creative-tim.com/product/notus-js"
                    class="text-blueGray-500 hover:text-gray-800"
                    target="_blank"
                  >
                    CINEF SAS
                  </a>{" "}
                  by{" "}
                  <a
                    href="/"
                    class="text-blueGray-500 hover:text-blueGray-800"
                    target="_blank"
                  >
                    {" "}
                    Cotonou - Bénin
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
}
