import TypeWriterEffect from "react-typewriter-effect";
import React, { Text, useState } from "react";
import axios from "axios";
import Header from "./Header";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./Accueil.css";

export default function App() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errormsg, setErrormsg] = React.useState("");

  //Function onclick loading button

    const handleClick = () => {
      setLoading(true);
      // Perform operation and then set loading to false
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

  //Onsubmit form
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();
    var { reference } = document.forms[0];

    const codeData = {
      reference: reference.value,
    };
    const certiDataJson = JSON.stringify(codeData);
    //const accessToken = localStorage.getItem("user-token");
    //console.log(certiDataJson);

    axios
      .get(
        process.env.React_App_Uri_api + "certificats/" + reference.value,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            // "params": { certiDataJson },
            // Authorization: `Bearer ${localStorage.getItem("user-token")}`,
          },
        }
      )
      .then(function (response) {
        //console.log(response.data);
        const dataCertif = response.data;

        //console.log(certificats)
        if (response.data === "") {
          alert(
            "Désolé, cette attestation n'est pas dans notre régistre ou le code de référence n'est pas correct !"
          );
          setErrormsg(
            "Désolé, cette attestation n'est pas dans notre régistre ou le code de référence n'est pas correct ! "
          );
        }
        if (response.data.reference) {
          navigate("/attestationview", {
            state: {
              fullname: dataCertif.fullname,
              reference: dataCertif.reference,
              theme: dataCertif.theme,
              lieu: dataCertif.lieu,
              structure: dataCertif.structure,
              typeformation: dataCertif.typeformation,
              intervenant: dataCertif.intervenant,
              intervenant2: dataCertif.intervenant2,
              dateon: dataCertif.dateon,
              titre: dataCertif.titre,
              titre2: dataCertif.titre2,
            },
          });
        }
        //setSuccesmsg(response.data.message);
        //const statusCode = response.status;
        // console.log(statusCode);
      })
      .catch((e) => {
        //const errorServer= console.log(e.response.status);

        setErrormsg(
          "Oouuf, une erreur s'est produite.Veuillez patienter et rééssayer plus  ! "
        );
      });
  };

  return (
    <>
      <div class="bg"></div>
      <div class="bg bg2"></div>
      <div class="bg bg3"></div>
      {/* <div class="content"></div> */}
      <main class="dark:bg-gray-800 bg-white overflow-hidden  h-screen ">
        <Header />
        <div class="bg-gray-700 dark:bg-gray-800 flex relative z-20 items-center ">
          <div class="container mx-auto px-6 flex flex-col justify-between items-center relative py-8">
            <div class="container flex flex-col mx-auto ">
              <h1 class="font-bold w-full uppercase text-center text-3xl sm:text-5xl dark:text-white text-white">
                IMPRESSION D'ATTESTATION DE FORMATION CINEF
              </h1>
              <h2
                class="font-light max-w-2xl mx-auto w-full text-xl justify-items-center dark:text-white text-gray-400 text-center py-4 "
                id="mobile-menu-2"
              >
                Nos attestations/certificats sont digitalisé(e)s et offrent une
                facilité d'utilisation et de vérification n'importe où et
                n'importe quand.
              </h2>

              <div class="container text-center mx-auto px-6 flex flex-col justify-between items-center relative py-4">
                <TypeWriterEffect
                  textStyle={{
                    fontFamily: "Exo:sans-serif",
                    color: "yellow",
                    fontWeight: 500,
                    fontSize: "1.5em",
                    textAlign: "center",
                  }}
                  startDelay={2000}
                  cursorColor="white"
                  multiText={[
                    "A quoi ressemble mon certificat numérique CINEF ? ",
                    "le numéro de référence ",
                    "le nom du cours/formation...",
                    "le code QR de confirmation de son authenticité ",
                    "le nom du stagiaire et de son entité",
                  ]}
                  multiTextDelay={1000}
                  typeSpeed={60}
                  deleteSpeed={50}
                  delaySpeed={1000}
                  loop={0}
                />
              </div>
              <p class="text-red-500 text-xl text-center mt-2 ">{errormsg}</p>
              <div class="flex items-center justify-center mt-4 ">
                <a
                  href="#"
                  onClick={() => setShow(!show)}
                  class="uppercase py-2 px-4 text-center bg-green-800 border-2 font-bold border-transparent text-white text-md mr-4 hover:bg-gray-900"
                >
                  Telecharger mon attestation 🔑
                </a>
                {/*    <a href="#" onClick={() => setShow1(!show1)} class="uppercase py-2 px-4 bg-white border-2 border-white text-gray-800 dark:text-white hover:bg-gray-800 hover:text-white text-md">
                                  Verifier un certificat
                              </a>*/}
              </div>
            </div>
            <div class="justify-center mt-2 mb-4  py-4 text-white">
              {show && (
                <form
                  method="post"
                  id="revue-form"
                  name="confirmation"
                  onSubmit={handleSubmit}
                  className="flex items-center"
                >
                  <div className="flex space-x-1">
                    <input
                      type="text"
                      className="block w-full px-4 py-2 text-gray-600 bg-white border rounded-full focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="cinef-e4eg"
                      name="reference"
                      id="reference"
                      required
                    />
                    <button
                      className={`bg-gray-200 hover:bg-green-700 text-white py-2 px-4 rounded-full ${
                        loading ? "cursor-not-allowed opacity-25" : ""
                      }`}
                      onClick={handleClick}
                      enable={loading}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              )}{" "}
            </div>

            <div class="justify-center mb-4  text-white">
              {show1 && (
                <form className="max-w-sm ">
                  <div className="relative">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 left-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </button>
                    <input
                      type="text"
                      placeholder="Saisir le nom de l'impétrant"
                      className="w-full py-3 pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white focus:border-indigo-600"
                    />
                  </div>
                </form>
              )}
            </div>
            <div class=" mt-6 md:mt-0 px-4">
              <img
                src=".././images/modele_attestation_cinef.png"
                class="max-w-xs md:max-w-2xl mx-auto"
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
