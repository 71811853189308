import "./index.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Accueil";
import Attestationview from "./Components/Attestationview";
import Confirmationducode from "./Components/Confirmationducode";
import Confirmationpage from "./Components/Confirmationpage";
import Login from "./Components/Login";
import Blogs from "./Components/Blogs";
import Contact from "./Components/Contact";
import Dashboard from "./Components/dash/Dashboard";
import Createdcertificat from "./Components/Createdcertificat";
import Passwordforgot from "./Components/Passwordforgot";
import Templatecertificat from "./Components/Templatecertificat";
import Userdash from "./Components/dash/Userdash";
import Addformation from "./Components/dash/Addformation";
import Addformateur from "./Components/dash/Addformateur";
import Allattestations from "./Components/dash/Allattestations";
import Allformations from "./Components/dash/Allformations";
import Allformateurs from "./Components/dash/Allformateurs";
import Adduser from "./Components/dash/Adduser";

export default function App() {



  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          {" "}
        </Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/passwordforgot" element={<Passwordforgot />}></Route>
        <Route path="/dash/dashboard" element={<Dashboard />}></Route>
        <Route path="/dash/userdash" element={<Userdash />}></Route>
        <Route path="/dash/addformation" element={<Addformation />}></Route>
        <Route path="/dash/addformateur" element={<Addformateur />}></Route>
        <Route path="/attestationview" element={<Attestationview />}></Route>
        <Route
          path="/dash/allattestations"
          element={<Allattestations />}
        ></Route>
        <Route path="/dash/allformations" element={<Allformations />}></Route>
        <Route path="/dash/allformateurs" element={<Allformateurs />}></Route>
        <Route
          path="/confirmationducode"
          element={<Confirmationducode />}
        ></Route>
        <Route path="/confirmationpage" element={<Confirmationpage />}></Route>
        <Route
          path="/createdcertificat"
          element={<Createdcertificat />}
        ></Route>
        <Route
          path="/templatecertificat"
          element={<Templatecertificat />}
        ></Route>
        <Route path="/dash/adduser" element={<Adduser />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
