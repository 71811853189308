import React from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [errorMessages, setErrorMessages] = useState({});
  const [loading, setLoading] = useState(false);

  const loginAPI = process.env.React_App_Uri_api + "auth/login";

  const navigate = useNavigate();

  const errors = {
    email: "Veuillez saisir une adresse email correcte !",
    password: "Vérifer votre mot de passe !",
    login: "Ouf, veuillez verifier votre email et mot de passe et rééssayer !",
  };

  //Function onclick loading button

  const handleClick = () => {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleSubmit = async (event) => {
    //Prevent page reload

    event.preventDefault();

    var { email, password } = document.forms[0];

    sessionStorage.setItem("UserName", email.value);
    const dataSend = {
      email: email.value,
      password: password.value,
    };

    const formDataJSON = JSON.stringify(dataSend);

    /* console.log(formDataJSON )*/

    axios
      .post(loginAPI, formDataJSON, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        /*console.log(response);*/
        const token = response.data.access_token;
        const userData = response.status;

        if (!token) {
          alert(
            "Oouuf, Impossible de se connecter. Veuillez essayer après un certain temps !"
          );
          return;
        }

        localStorage.clear();
        localStorage.setItem("user-token", token);

        //SEND WHATSAPP MSG

        var data = JSON.stringify({
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: 22995898072,
          type: "template",
          template: {
            name: "notification_de_connexion",
            language: {
              code: "fr",
            },
            components: [
              {
                type: "header",
                parameters: [
                  {
                    type: "image",
                    image: {
                      link: "https://www.cinef-bj.com/formations/wp-content/uploads/2020/03/logo_cinef-1.png",
                    },
                  },
                ],
              },
              {
                type: "body",
                parameters: [
                  {
                    type: "text",
                    text: dataSend.email,
                  },
                ],
              },
            ],
          },
        });
        //const uri_whatsapp = process.env.React_App_WhatsApp_uri;
        //console.log(uri_whatsapp);

        var config = {
          method: "post",
          url: process.env.React_App_WhatsApp_uri,
          headers: {
            Authorization: `Bearer ${process.env.React_App_WhatsApp_token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
            const res = response.statusText;
          })
          .catch(function (error) {
            //console.log(error);
          });

        //SEND WHATSAPP MSG

        //Déconnection automatique
        setTimeout(() => {
          navigate("/login");
        }, 500000);

        // Compare user info
        if (
          userData === 201 &&
          dataSend.email === "marieangeaboudou@gmail.com"
        ) {
          navigate("../dash/userdash");
        }

        if (userData === 201 && dataSend.email === "lynoshk@yahoo.fr") {
          navigate("../dash/dashboard");
        }

        if (
          userData === 401 ||
          userData === 404 ||
          dataSend.email != "lynoshk@yahoo.fr" ||
          dataSend.email != "marieangeaboudou@gmail.com"
        ) {
          setErrorMessages({ name: "login", message: errors.login });
        }
      })
      .catch(function (e) {
        setErrorMessages({ name: "email", message: errors.email });
        setErrorMessages({ name: "password", message: errors.password });
        setErrorMessages({ name: "login", message: errors.login });
        /*console.log(e);*/
      });

    // if ( email.value && password.value) {
    /*alert("User successfully login !");*/
    //  return navigate("/dashboard");
    // }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
        <a href="/" target="_blank" class="flex justify-center mb-12">
          <img
            src=".././images/logo_cinef_ok.png"
            class="w-48 justify-center"
            alt="Flowbite Logo"
          />
        </a>
        <h1 className="text-3xl font-semibold text-center text-green-700 ">
          Se connecter
        </h1>

        <form
          className="mt-4"
          method="post"
          name="userLoginForm"
          onSubmit={handleSubmit}
        >
          <p class="text-center text-red-900"> {renderErrorMessage("login")}</p>
          <div className="mb-2">
            <label
              for="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              className="block w-full px-4 py-2 mt-2 text-green-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
              required
            />
          </div>
          <p class="text-red-900"> {renderErrorMessage("email")}</p>

          <div className="mb-2">
            <label
              for="password"
              className="block text-sm font-semibold text-gray-800"
            >
              Mot de passe
            </label>
            <input
              type="password"
              name="password"
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
              required
            />
          </div>
          <p class="text-red-900">{renderErrorMessage("password")}</p>
          <a
            href="/passwordforgot"
            className="text-xs text-red-800 hover:underline"
          >
            Forget Password?
          </a>
          <div className="mt-6">
            <button
              type="submit"
              value="Login"
              className={`bg-green-700 hover:bg-gray-700 text-white py-2 px-4 border border-white rounded ${
                loading ? "cursor-not-allowed opacity-25" : ""
              }`}
              onClick={handleClick}
              enable={loading}
            >
              {loading ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 animate-spin mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              ) : (
                "Valider"
              )}
            </button>
          </div>
        </form>

        <p className="mt-8 text-xs font-light text-center text-green-700"> </p>
      </div>
    </div>
  );
}
