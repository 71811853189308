import TypeWriterEffect from 'react-typewriter-effect';
import React, {Text, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";



export default function App() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

const navigate = useNavigate();
const certiData=useLocation();
//console.log(certiData.state);



if (certiData.state === null || certiData.state === undefined ) {
  {/*alert(
    "No match, la session a expirée. Veuillez flacher à nouveau et rééssayer !"
  );*/}
  if (
    window.confirm(
      "No match, la session a expirée. Veuillez flacher le code Qr à nouveau et rééssayer !"
    )
  ) {
    // Save it!
    //console.log("Heloo")
    
   window.location.href =("./confirmationducode");
  } 
    
}
  
  return (
    <>
      <div class="container mx-auto bg-white dark:bg-gray-800 flex items-center">
        <div class="mx-auto bg-white px-6 flex flex-col justify-between items-center ">
          <div class="flex flex-col bg-white">
            <a
              class="mt-4 mx-auto"
              href="https://cinef-bj.com/formations"
              target="_blank"
            >
              <img
                src=".././images/logo_cinef_ok.png"
                class="w-48"
                alt="CINEF Logo"
              />
            </a>
            <h1 class="font-bold w-full uppercase text-center mt-8 text-xl sm:text-5xl dark:text-white text-green-900">
              CONFIRMATION DE L' ATTESTATION DE CINEF
            </h1>
            <h2 class="font-light max-w-2xl mx-auto  text-xl dark:text-white text-gray-700 text-center py-4 ">
              Nos attestions/certificats sont digitalisé(e)s et offrent une
              facilité d'utilisation et de vérification n'importe ou et
              n'importe quand.
            </h2>
          </div>

          {/*ATTESTATION RENDER*/}

          <section class=" bg-white text-gray-900 font-sans overflow-x-hidden">
            <div class="md:flex md:items-center md:justify-center">
              <div class="bg-white rounded-lg  md:mx-auto p-4 w-full inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
                <div class="md:flex items-center ">
                  <div class="rounded-full animate-ping mx-auto border-8 border-gray-300 flex items-center justify-center w-32 h-32 flex-shrink-0 mx-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5em"
                      height="5em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                        <path
                          fill="green"
                          d="M11.298 2.195a2 2 0 0 1 1.232-.055l.172.055l7 2.625a2 2 0 0 1 1.291 1.708l.007.165v5.363a9 9 0 0 1-4.709 7.911l-.266.139l-3.354 1.677a1.5 1.5 0 0 1-1.198.062l-.144-.062l-3.354-1.677a9 9 0 0 1-4.97-7.75l-.005-.3V6.693a2 2 0 0 1 1.145-1.808l.153-.065l7-2.625Zm4.135 6.434l-4.598 4.598l-1.768-1.768a1 1 0 0 0-1.414 1.415l2.404 2.404a1.1 1.1 0 0 0 1.556 0l5.234-5.235a1 1 0 1 0-1.414-1.414Z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="mt-4 md:mt-0 md:ml-6 text-justify w-auto text-center border-8 rounded-lg bg-gray-200 px-4 md:text-left">
                    <p class="font-bold uppercase text-xl mt-4 text-green-700 text-center">
                      Attestation confirmée !!!
                    </p>
                    <p class="text-md text-gray-700 mt-1 justify-center">
                      Cette attestation a été délivée par CINEF SAS et inscrite
                      dans son régistre pour servir et favoir ce que de droit.
                      Nous l'affirmons sur l'honneur.
                    </p>
                    <p class=" font-bold text-sm">Code de référence : </p>
                    <span class="text-green-700 font-bold">
                      {" "}
                      {certiData.state.reference}
                    </span>{" "}
                    <p class=" font-bold text-sm">
                      Nom et prénom(s) de l'impétrant :{" "}
                    </p>
                    <span class="text-green-700 font-bold">
                      {" "}
                      {certiData.state.fullname}
                    </span>
                    {/* <p class="font-bold">Thème de la formation :</p>
                   <p class="text-red-700 font-bold"> {certiData.state.theme}</p> */}
                    <p class=" font-bold text-sm">Date de la session : </p>
                    <span class="text-red-700 font-bold">
                      {certiData.state.dateon}
                    </span>
                  </div>
                </div>
                <div class="text-center md:text-right mt-4 md:flex md:justify-end">
                  <a
                    href="https://www.cinef-bj.com/"
                    class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2"
                  >
                    Fermer
                  </a>
                  <a
                    href="./confirmationducode"
                    class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1"
                  >
                    Retour
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/*ATTESTATION RENDER*/}
      </div>
    </>
  );
}
