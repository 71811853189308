import React from "react";
import Header from "../Header";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Protedroute from "../dash/Protedroute";
import axios from "axios";
//jQuery libraries

import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
//import "https://cdn.datatables.net/1.13.1/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

export default function Dashboard() {
  const [darkToggle, setDarkToggle] = React.useState(false);
  const navigate = useNavigate();
  const [errormsg, setErrormsg] = React.useState("");
  const [succesmsg, setSuccesmsg] = React.useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [usersdata, setUsersdata] = useState([]);
  const [pre, setPre] = useState("");
  const [att, setAtt] = useState("");
  const [histodownload, setHistodownload] = useState("");

  const result = React.useState(sessionStorage.getItem("UserName"));
  const usersUrl = process.env.React_App_Uri_api + "auth";
  const attestasUrl = process.env.React_App_Uri_api + "certificats";
  const downloadUrl = process.env.React_App_Uri_api + "downloads";

  const [searchVal, setSearchVal] = useState(null);

  const user = sessionStorage.getItem("UserName");
  /*console.log(user);*/

  /*CheckUserIdentity*/
  const checkUserprofile = () => {
    const userInfo = sessionStorage.getItem("UserName");
    if (userInfo === null) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
  };
  useEffect(() => {
    checkUserprofile();
  });

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  //GET COUNT ALL ATTESTION

  const getallAttesData = () => {
    axios
      .get(attestasUrl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      })
      .then(function (response) {
        //console.log(response);
        if (response.data.length <= 9) {
          setAtt("0" + response.data.length);
        }
        if (response.data.length > 9) {
          setAtt(response.data.length);
        }

        //const statusCode = response.status;
        //console.log(certificatsdata);
      }); //initialize datatable
  };

  //GET ATTESTATION DOWNLOAD HISTORIQUE
const downloadHisto = () => {
  axios
    .get(downloadUrl, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-token")}`,
      },
    })
    .then(function (response) {
      //console.log(response.data.length);
      
        setHistodownload(response.data.length);
   
      //const statusCode = response.status;
      //console.log(certificatsdata);
    }); //initialize datatable
};



  //GET ATTESTATION DOWNLOAD HISTORIQUE

  //GET ALL USERS
  const getallUsersData = () => {
    axios
      .get(usersUrl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      })
      .then(function (response) {
        //console.log(response);
        setUsersdata(response.data.users);
        if (response.data.users.length <= 9) {
          setPre("0" + response.data.users.length);
        }
        if (response.data.users > 9) {
          setPre(response.data.users.length);
        }

        //const statusCode = response.status;
        //console.log(certificatsdata);
      }); //initialize datatable
  };
  //console.log(usersdata);
  useEffect(() => {
    const timer = setTimeout(() => {
      getallUsersData();
      getallAttesData();
      downloadHisto();
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Rechercher&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 5000);
  });

  //DELETE ATTESTATION

  if (userId !== "") {
    //console.log("Etes-vous sûr de vouloir supprimer!");
    const confirm = window.confirm(
      "Etes-vous sûr de vouloir supprimer cet utilisateur ?"
    );
    // console.log(userId);

    if (confirm === true) {
      axios
        .delete(usersUrl + "/" + userId, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user-token")}`,
          },
        })
        .then(function (response) {
          //console.log(response);
          setSuccesmsg("Cet utilisateur a été supprimée avec succès !");
          window.location.href = "./dashboard";
          //alert(response.data.status);
          //const statusCode = response.status;
          // console.log(statusCode);
        })
        .catch((e) => {
          //const errorServer= console.log(e.response.status);

          setErrormsg(
            "Oouf, une erreur est survenue ! Veuillez signaler l'erreur à l'administrateur. "
          );
        });

      //axios.delete(certificatUrl + "/" + certId);
    }
    if (confirm === false) {
      window.location.href = "./dashboard";
    }
  }

  return (
    <>
      <Protedroute />

      <div class={`  ${darkToggle && "bg-gray-900"}`}>
        <aside class=" ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r bg-{dark}  transition duration-300 md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]  ">
          <div>
            <div class="float-right relative">
              <a href="#" title="TABLEAU DE BORD">
                <img
                  src=".././images/CARRE_CINEF.jpeg"
                  class="w-8 rounded-full "
                  alt="tailus logo"
                ></img>
                Connecté
              </a>
            </div>

            <div class="text-center ">
              <img
                src=".././images/dev.png"
                alt=""
                class="w-10 h-10 m-auto rounded-full object-cover lg:w-28 lg:h-28"
              ></img>
              <h5 class="hidden mt-4 text-xl font-semibold text-gray-600 lg:block">
                Dev {result}
              </h5>
              <span class="hidden text-gray-400 lg:block">Administrateur</span>
            </div>

            <ul class="space-y-2 tracking-wide mt-8">
              <li>
                <a
                  href="#"
                  aria-label="dashboard"
                  class="relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white bg-green-700 from-sky-600 to-cyan-400"
                >
                  <svg class="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                      class="fill-current text-cyan-400 dark:fill-slate-600"
                    ></path>
                    <path
                      d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                      class="fill-current text-cyan-200 group-hover:text-cyan-300"
                    ></path>
                    <path
                      d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                      class="fill-current group-hover:text-sky-300"
                    ></path>
                  </svg>
                  <span class="-mr-1 font-medium">DASHBOARD</span>
                </a>
              </li>
              <li>
                <a
                  href="./adduser"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Utilisateur</span>
                </a>
              </li>
              {/*   <li>
                <a
                  href="#"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M15 0H9v7c0 .55.45 1 1 1h1v1h1V8h3c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-4 7h-1V6h1v1zm4 0h-3V6h3v1zm0-2h-4V1h4v4zM4 5H3V4h1v1zm0-2H3V2h1v1zM2 1h6V0H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h2v2l1.5-1.5L6 16v-2h5c.55 0 1-.45 1-1v-3H2V1zm9 10v2H6v-1H3v1H1v-2h10zM3 8h1v1H3V8zm1-1H3V6h1v1z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Attestations</span>
                </a>
              </li>

               <li>
                   <a
                     href="#"
                     class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                   >
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="1em"
                       height="1em"
                       preserveAspectRatio="xMidYMid meet"
                       viewBox="0 0 16 16"
                     >
                       <path
                         fill="currentColor"
                         fill-rule="evenodd"
                         d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                       />
                     </svg>
                     <span class="group-hover:text-gray-700">Downloads</span>
                   </a>
                 </li>
                 <li>
                   <a
                     href="#"
                     class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                   >
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="1em"
                       height="1em"
                       preserveAspectRatio="xMidYMid meet"
                       viewBox="0 0 2048 2048"
                     >
                       <path
                         fill="currentColor"
                         d="M918 1881q-10 38-15 77t-7 80q-128-15-244-60t-215-115t-180-162t-138-200t-88-228t-31-249q0-141 36-272t103-245t160-207t208-160T751 37t273-37q141 0 271 36t245 104t207 160t161 208t103 244t37 272h-71q-14-23-29-45t-35-42q-6-87-31-169H657q-8 64-12 127t-5 129q0 65 4 128t13 128h367q0 32 5 64t13 64H679q12 57 32 125t50 133t69 122t88 93zm915-1241q-39-81-92-152t-120-130t-142-105t-162-75q36 50 64 106t51 115t39 120t28 121h334zm-809-512q-50 0-92 27t-78 71t-64 99t-51 113t-37 110t-23 92h690q-8-39-23-92t-37-110t-50-112t-64-100t-79-71t-92-27zm-292 49q-85 29-162 74T427 356T307 487t-92 153h334q11-55 27-117t40-124t52-119t64-103zm-604 847q0 133 37 256h363q-8-64-12-127t-4-129q0-65 4-128t12-128H165q-37 123-37 256zm85 384q39 81 92 152t120 131t144 104t162 75q-36-50-64-106t-51-115t-39-120t-29-121H213zm1549 181q65 33 118 81t90 108t58 128t20 142h-128q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149h-128q0-73 20-141t57-129t90-108t118-81q-75-54-116-135t-41-174q0-79 30-149t82-122t122-83t150-30q79 0 149 30t122 82t83 123t30 149q0 92-41 173t-117 136zm-226-53q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100q0 53 20 99t55 82t81 55t100 20z"
                       />
                     </svg>
                     <span class="group-hover:text-gray-700">En ligne</span>
                   </a>
     </li>*/}
            </ul>
          </div>

          <div class="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
            <button
              class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
              onClick={logout}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <span class="group-hover:text-gray-700">Déconnexion</span>
            </button>
          </div>
        </aside>
        <div class="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
          <div class="sticky z-10 top-0 h-16 border-b bg-white lg:py-2.5">
            <div class="px-6 flex items-center justify-between space-x-4 2xl:container">
              <h5 hidden class="text-2xl text-gray-600 font-bold lg:block">
                TABLEAU DE BORD
              </h5>
              <button class="w-12 h-16 -mr-2 border-r lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 my-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>

              {/* Bar seach */}
              <div class="flex space-x-4">
                <div hidden class="md:block">
                  <div class="relative flex items-center text-gray-400  focus-within:text-cyan-400"></div>
                </div>

                <button
                  onClick={() => setDarkToggle(!darkToggle)}
                  aria-label="chat"
                  class="w-10 h-10 rounded-xl border  bg-gray-400 focus:bg-gray-900 active:bg-gray-400"
                >
                  <svg
                    class="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="white"
                      stroke-dasharray="2"
                      stroke-dashoffset="2"
                      stroke-linecap="round"
                      stroke-width="2"
                    >
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.6s"
                          dur="0.2s"
                          values="M12 19v1M19 12h1M12 5v-1M5 12h-1;M12 21v1M21 12h1M12 3v-1M3 12h-1"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.6s"
                          dur="0.2s"
                          values="2;0"
                        />
                      </path>
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.9s"
                          dur="0.2s"
                          values="M17 17l0.5 0.5M17 7l0.5 -0.5M7 7l-0.5 -0.5M7 17l-0.5 0.5;M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.9s"
                          dur="1.2s"
                          values="2;0"
                        />
                      </path>
                      <animateTransform
                        attributeName="transform"
                        dur="30s"
                        repeatCount="indefinite"
                        type="rotate"
                        values="0 12 12;360 12 12"
                      />
                    </g>
                    <mask id="svgIDa">
                      <circle cx="12" cy="12" r="12" fill="#fff" />
                      <circle cx="18" cy="6" r="12" fill="#fff">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="12;3"
                        />
                      </circle>
                      <circle cx="18" cy="6" r="10">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="10;1"
                        />
                      </circle>
                    </mask>
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      fill="gray"
                      mask="url(#svgIDa)"
                    >
                      <animate
                        fill="freeze"
                        attributeName="r"
                        dur="0.4s"
                        values="10;6"
                      />
                    </circle>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div class="px-6 pt-6 2xl:container">
            <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <div class="md:col-span-2 lg:col-span-1">
                <div class="h-full py-8 px-6 space-y-6 rounded-xl border border-gray-200 bg-white">
                  <svg
                    class=" mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10em"
                    height="10em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="green"
                      d="M2 5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3.146a4.508 4.508 0 0 0-1-.678V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h7.258c.076.113.157.223.242.329V15H4a2 2 0 0 1-2-2V5Zm16.5 6.5c0 .954-.381 1.818-1 2.45V18a.5.5 0 0 1-.8.4l-1.4-1.05a.5.5 0 0 0-.6 0l-1.4 1.05a.5.5 0 0 1-.8-.4v-4.05a3.5 3.5 0 1 1 6-2.45ZM15 15c-.537 0-1.045-.12-1.5-.337v2.087l1.243-.746a.5.5 0 0 1 .514 0l1.243.746v-2.087A3.486 3.486 0 0 1 15 15Zm0-1a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5ZM5 6.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5Zm.5 4.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4Z"
                    />
                  </svg>
                  <div>
                    <h5 class="text-xl text-white bg-green-700 rounded-lg w-60 mx-auto font-bold text-center">
                      Attestations/Certificats
                    </h5>
                    <div class="mt-8 flex justify-center gap-4">
                      <h3 class="text-3xl font-bold text-gray-700">{att}</h3>
                      <div class="flex items-end gap-1 text-green-500">
                        <svg
                          class="w-3"
                          viewBox="0 0 12 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.00001 0L12 8H-3.05176e-05L6.00001 0Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div class="flex items-end gap-1 text-green-500"></div>
                    </div>
                    <span class="mt-8 block text-center text-gray-500">
                      Documents délivrés
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div class="h-full py-6 px-6 rounded-xl border border-gray-200 bg-white mb-4">
                  <svg
                    class="w-full"
                    viewBox="0 0 218 69"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 67.5C27.8998 67.5 24.6002 15 52.5 15C80.3998 15 77.1002 29 105 29C132.9 29 128.6 52 156.5 52C184.4 52 189.127 63.8158 217.027 63.8158"
                      stroke="url(#paint0_linear_622:13664)"
                      stroke-width="3"
                      stroke-linecap="round"
                    />
                    <path
                      d="M0 67.5C27.2601 67.5 30.7399 31 58 31C85.2601 31 80.7399 2 108 2C135.26 2 134.74 43 162 43C189.26 43 190.74 63.665 218 63.665"
                      stroke="url(#paint1_linear_622:13664)"
                      stroke-width="3"
                      stroke-linecap="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_622:13664"
                        x1="217.027"
                        y1="15"
                        x2="7.91244"
                        y2="15"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="green-500" />
                        <stop offset="1" stop-color="red" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_622:13664"
                        x1="218"
                        y1="18.3748"
                        x2="5.4362"
                        y2="18.9795"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="green" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <h5 class="mt-16 text-xl text-white bg-green-700 text-center mt-4 rounded-lg w-40 mx-auto font-bold">
                    Downloads
                  </h5>
                  <div class="my-8">
                    <h3 class="text-xl font-bold text-center text-red-700 border-2 border-green-700 w-24 mx-auto p-8 rounded-full animate-bounce">
                      {histodownload}
                    </h3>
                  </div>
                  <span class="block text-center text-gray-500">
                    Chargement des attestations délivrées
                  </span>
                </div>
              </div>
              <div>
                <div class="lg:h-full py-8 px-6 text-gray-600 rounded-xl border border-gray-200 bg-white">
                  <svg
                    class="text-center mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10em"
                    height="10em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="green"
                      d="M41 7C31.6-2.3 16.4-2.3 7 7s-9.3 24.6 0 34l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L41 7zm558 0l-72 72c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l72-72c9.4-9.4 9.4-24.6 0-33.9S608.3-2.4 599 7zM7 505c9.4 9.4 24.6 9.4 33.9 0l72-72c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 471c-9.4 9.4-9.4 24.6 0 33.9zm592 0c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-72-72c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l72 72zM320 256c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm-107.9 80c-2.7 7.5-4.1 15.6-4.1 24c0 13.3 10.7 24 24 24h176c13.3 0 24-10.7 24-24c0-8.4-1.4-16.5-4.1-24c-.5-1.4-1-2.7-1.6-4c-9.4-22.3-29.8-38.9-54.3-43c-3.9-.7-7.9-1-12-1h-80c-4.1 0-8.1.3-12 1c-.8.1-1.7.3-2.5.5c-24.9 5.1-45.1 23-53.4 46.5zm-36.3-112c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm-26.5 32c-29.4 0-53.3 23.9-53.3 53.3c0 14.7 11.9 26.7 26.7 26.7h56.1c8-34.1 32.8-61.7 65.2-73.6c-7.5-4.1-16.2-6.4-25.3-6.4h-69.4zm368 80c14.7 0 26.7-11.9 26.7-26.7c0-29.5-23.9-53.3-53.3-53.3h-69.4c-9.2 0-17.8 2.3-25.3 6.4c32.4 11.9 57.2 39.5 65.2 73.6h56.1zM464 224c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48z"
                    />
                  </svg>
                  <div class="mt-6">
                    <h5 class="text-xl text-white bg-green-700 text-center rounded-lg w-64 mx-auto font-bold">
                      Nombre d'utilisateur(s)
                    </h5>
                    <div class="mt-8 flex justify-center gap-4">
                      <h3 class="text-3xl font-bold text-gray-700">{pre}</h3>
                      <div class="flex items-end gap-1 text-green-500">
                        <svg
                          class="w-3"
                          viewBox="0 0 12 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.00001 0L12 8H-3.05176e-05L6.00001 0Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <span class="mt-8 block text-center text-gray-500">
                      Compte(s) utilisateur(s)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full justify-center mx-auto  text-gray-500 text-center">
            <p className="text-2xl font-bold mt-8">LISTE DES UTILISATEURS</p>
            <p class="text-green-500 text-xl text-center mt-4 ">{succesmsg}</p>
            <p class="text-red-500 text-xl text-center mt-4 ">{errormsg}</p>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table
                id="example"
                class="w-full text-sm text-center text-gray-700 mx-auto  dark:text-gray-400"
              >
                <thead class="text-xs  text-gray-500 uppercase bg-white bg-gray-200 p-4 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="p-4 ">
                      <div class="flex items-center"> Réf</div>
                    </th>

                    <th scope="col" class="px-6 py-3">
                      Nom et Prénom(s)
                    </th>

                    <th scope="col" class="px-6 py-3">
                      email
                    </th>

                    <th scope="col" class="px-6 py-3">
                      Action In
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Action Out
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usersdata.map((userdata, i) => (
                    <tr
                      key={i}
                      class="bg-white border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700 text-gray-700  hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td class="px-2 border-b-2 ">{userdata._id}</td>
                      <th
                        scope="row "
                        class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border-b-2 border-gray-200 "
                      >
                        {userdata.fullname}
                      </th>
                      <td class="px-6 py-4 border-b-2 border-gray-200">
                        {userdata.email}
                      </td>

                      <td class="px-6 py-4 border-b-2 ">
                        <button
                          onClick={() => setIsOpen(true)}
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 36 36"
                          >
                            <path
                              fill="green"
                              d="M19.5 28.1h-2.9c-.5 0-.9-.3-1-.8l-.5-1.8l-.4-.2l-1.6.9c-.4.2-.9.2-1.2-.2l-2.1-2.1c-.3-.3-.4-.8-.2-1.2l.9-1.6l-.2-.4l-1.8-.5c-.4-.1-.8-.5-.8-1v-2.9c0-.5.3-.9.8-1l1.8-.5l.2-.4l-.9-1.6c-.2-.4-.2-.9.2-1.2l2.1-2.1c.3-.3.8-.4 1.2-.2l1.6.9l.4-.2l.5-1.8c.1-.4.5-.8 1-.8h2.9c.5 0 .9.3 1 .8L21 10l.4.2l1.6-.9c.4-.2.9-.2 1.2.2l2.1 2.1c.3.3.4.8.2 1.2l-.9 1.6l.2.4l1.8.5c.4.1.8.5.8 1v2.9c0 .5-.3.9-.8 1l-1.8.5l-.2.4l.9 1.6c.2.4.2.9-.2 1.2L24.2 26c-.3.3-.8.4-1.2.2l-1.6-.9l-.4.2l-.5 1.8c-.2.5-.6.8-1 .8zm-2.2-2h1.4l.5-2.1l.5-.2c.4-.1.7-.3 1.1-.4l.5-.3l1.9 1.1l1-1l-1.1-1.9l.3-.5c.2-.3.3-.7.4-1.1l.2-.5l2.1-.5v-1.4l-2.1-.5l-.2-.5c-.1-.4-.3-.7-.4-1.1l-.3-.5l1.1-1.9l-1-1l-1.9 1.1l-.5-.3c-.3-.2-.7-.3-1.1-.4l-.5-.2l-.5-2.1h-1.4l-.5 2.1l-.5.2c-.4.1-.7.3-1.1.4l-.5.3l-1.9-1.1l-1 1l1.1 1.9l-.3.5c-.2.3-.3.7-.4 1.1l-.2.5l-2.1.5v1.4l2.1.5l.2.5c.1.4.3.7.4 1.1l.3.5l-1.1 1.9l1 1l1.9-1.1l.5.3c.3.2.7.3 1.1.4l.5.2l.5 2.1zm9.8-6.6z"
                            />
                            <path
                              fill="green"
                              d="M18 22.3c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3zm0-6.6c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3c1.3 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3z"
                            />
                            <path
                              fill="green"
                              d="M18 2c-.6 0-1 .4-1 1s.4 1 1 1c7.7 0 14 6.3 14 14s-6.3 14-14 14S4 25.7 4 18c0-2.8.8-5.5 2.4-7.8v1.2c0 .6.4 1 1 1s1-.4 1-1v-5h-5c-.6 0-1 .4-1 1s.4 1 1 1h1.8C3.1 11.1 2 14.5 2 18c0 8.8 7.2 16 16 16s16-7.2 16-16S26.8 2 18 2z"
                            />
                            <path fill="none" d="M0 0h36v36H0z" />
                          </svg>
                        </button>
                      </td>
                      <td class="px-6 py-4 text-right border-b-2 ">
                        <button
                          onClick={() => setUserId(userdata._id)}
                          class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="red"
                              d="m9.4 16.5l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6L16 9.9l-1.4-1.4l-2.6 2.6l-2.6-2.6L8 9.9l2.6 2.6L8 15.1ZM7 21q-.825 0-1.412-.587Q5 19.825 5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413Q17.825 21 17 21Z"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                      
                {isOpen && (
                  <div>
                            
                    <div className="container text-center text-gray-500 font-bold">
                                *Mise à jour du compte         
                    </div>
                            
                    <button
                      onClick={() => setIsOpen(false)}
                      className="font-medium text-white border p-4 w-30 h-15 float-right mb-4 mr-4 rounded-lg bg-red-500 dark:text-blue-500 "
                    >
                      Fermer
                    </button>
                           
                  </div>
                )}
                    
              </div>
            </div>
          </div>
          <script src="https://unpkg.com/flowbite@1.3.4/dist/flowbite.js"></script>
        </div>
        <footer class="relative bg-blueGray-50 pt-8 pb-6 mt-48">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap items-center md:justify-between justify-center">
              <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                <div class="text-sm text-blueGray-500 font-semibold py-1 bg-white rounded-full">
                  CopyRight@{" "}
                  <a
                    href="https://www.creative-tim.com/product/notus-js"
                    class="text-blueGray-500 hover:text-gray-800"
                    target="_blank"
                  >
                    CINEF SAS
                  </a>{" "}
                  by{" "}
                  <a
                    href="/"
                    class="text-blueGray-500 hover:text-blueGray-800"
                    target="_blank"
                  >
                    {" "}
                    Cotonou - Bénin
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
