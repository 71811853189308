import React from "react";
import Header from "./Header";



export default function Templatecertificat() {
  const [darkToggle, setDarkToggle] = React.useState(false);

  return (
    <>
      <div class={`  ${darkToggle && "bg-gray-900"}`}>
        <aside class=" ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r bg-{dark}  transition duration-300 md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]  ">
          <div>
            <div class="-mx-6 px-6 py-4">
              <a href="#" title="TABLEAU DE BORD">
                <img
                  src=".././images/CARRE_CINEF.jpeg"
                  class="w-16 rounded-full"
                  alt="tailus logo"
                ></img>
              </a>
            </div>

            <div class="mt-8 text-center ">
              <img
                src="https://pub-static.fotor.com/assets/projects/pages/d5bdd0513a0740a8a38752dbc32586d0/fotor-03d1a91a0cec4542927f53c87e0599f6.jpg"
                alt=""
                class="w-10 h-10 m-auto rounded-full object-cover lg:w-28 lg:h-28"
              ></img>
              <h5 class="hidden mt-4 text-xl font-semibold text-gray-600 lg:block">
                Marie-Ange
              </h5>
              <span class="hidden text-gray-400 lg:block">Resp. AA</span>
            </div>

            <ul class="space-y-2 tracking-wide mt-8">
              <li>
                <a
                  href="#"
                  aria-label="dashboard"
                  class="relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white bg-green-700 from-sky-600 to-cyan-400"
                >
                  <svg class="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                      class="fill-current text-cyan-400 dark:fill-slate-600"
                    ></path>
                    <path
                      d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                      class="fill-current text-cyan-200 group-hover:text-cyan-300"
                    ></path>
                    <path
                      d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                      class="fill-current group-hover:text-sky-300"
                    ></path>
                  </svg>
                  <span class="-mr-1 font-medium">DASHBOARD</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M15 0H9v7c0 .55.45 1 1 1h1v1h1V8h3c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-4 7h-1V6h1v1zm4 0h-3V6h3v1zm0-2h-4V1h4v4zM4 5H3V4h1v1zm0-2H3V2h1v1zM2 1h6V0H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h2v2l1.5-1.5L6 16v-2h5c.55 0 1-.45 1-1v-3H2V1zm9 10v2H6v-1H3v1H1v-2h10zM3 8h1v1H3V8zm1-1H3V6h1v1z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Attestations</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Ajouter</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Downloads</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 2048 2048"
                  >
                    <path
                      fill="currentColor"
                      d="M918 1881q-10 38-15 77t-7 80q-128-15-244-60t-215-115t-180-162t-138-200t-88-228t-31-249q0-141 36-272t103-245t160-207t208-160T751 37t273-37q141 0 271 36t245 104t207 160t161 208t103 244t37 272h-71q-14-23-29-45t-35-42q-6-87-31-169H657q-8 64-12 127t-5 129q0 65 4 128t13 128h367q0 32 5 64t13 64H679q12 57 32 125t50 133t69 122t88 93zm915-1241q-39-81-92-152t-120-130t-142-105t-162-75q36 50 64 106t51 115t39 120t28 121h334zm-809-512q-50 0-92 27t-78 71t-64 99t-51 113t-37 110t-23 92h690q-8-39-23-92t-37-110t-50-112t-64-100t-79-71t-92-27zm-292 49q-85 29-162 74T427 356T307 487t-92 153h334q11-55 27-117t40-124t52-119t64-103zm-604 847q0 133 37 256h363q-8-64-12-127t-4-129q0-65 4-128t12-128H165q-37 123-37 256zm85 384q39 81 92 152t120 131t144 104t162 75q-36-50-64-106t-51-115t-39-120t-29-121H213zm1549 181q65 33 118 81t90 108t58 128t20 142h-128q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149h-128q0-73 20-141t57-129t90-108t118-81q-75-54-116-135t-41-174q0-79 30-149t82-122t122-83t150-30q79 0 149 30t122 82t83 123t30 149q0 92-41 173t-117 136zm-226-53q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100q0 53 20 99t55 82t81 55t100 20z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">En ligne</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
            <button class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <span class="group-hover:text-gray-700">Déconnexion</span>
            </button>
          </div>
        </aside>
        <div class="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
          <div class="sticky z-10 top-0 h-16 border-b bg-white lg:py-2.5">
            <div class="px-6 flex items-center justify-between space-x-4 2xl:container">
              <h5 hidden class="text-2xl text-gray-600 font-bold lg:block">
                TABLEAU DE BORD
              </h5>
              <button class="w-12 h-16 -mr-2 border-r lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 my-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>

              {/* Bar seach */}
              <div class="flex space-x-4">
                <div hidden class="md:block">
                  <div class="relative flex items-center text-gray-400  focus-within:text-cyan-400"></div>
                </div>

                <button
                  onClick={() => setDarkToggle(!darkToggle)}
                  aria-label="chat"
                  class="w-10 h-10 rounded-xl border  bg-gray-400 focus:bg-gray-900 active:bg-gray-400"
                >
                  <svg
                    class="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="white"
                      stroke-dasharray="2"
                      stroke-dashoffset="2"
                      stroke-linecap="round"
                      stroke-width="2"
                    >
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.6s"
                          dur="0.2s"
                          values="M12 19v1M19 12h1M12 5v-1M5 12h-1;M12 21v1M21 12h1M12 3v-1M3 12h-1"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.6s"
                          dur="0.2s"
                          values="2;0"
                        />
                      </path>
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.9s"
                          dur="0.2s"
                          values="M17 17l0.5 0.5M17 7l0.5 -0.5M7 7l-0.5 -0.5M7 17l-0.5 0.5;M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.9s"
                          dur="1.2s"
                          values="2;0"
                        />
                      </path>
                      <animateTransform
                        attributeName="transform"
                        dur="30s"
                        repeatCount="indefinite"
                        type="rotate"
                        values="0 12 12;360 12 12"
                      />
                    </g>
                    <mask id="svgIDa">
                      <circle cx="12" cy="12" r="12" fill="#fff" />
                      <circle cx="18" cy="6" r="12" fill="#fff">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="12;3"
                        />
                      </circle>
                      <circle cx="18" cy="6" r="10">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="10;1"
                        />
                      </circle>
                    </mask>
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      fill="gray"
                      mask="url(#svgIDa)"
                    >
                      <animate
                        fill="freeze"
                        attributeName="r"
                        dur="0.4s"
                        values="10;6"
                      />
                    </circle>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* FORM ADD CERTIFICAT */}

        <div class="flex items-center justify-center p-12 bg-green-700  ml-64 ">
          <div className="App">
            <p className="byline text-white">Certificate of completion</p>
            <img
              src=".././images/logo_cinef_ok.png"
              class="w-48"
              alt="Flowbite Logo"
            />
            <div className="content ">
              <p class="ml-32 text-white">décernée à</p>
              <h1>Nom et Prénom(s)</h1>
              <p class="text-white">
                pour sa participation à la formation sur le thème:
              </p>
            </div>
            <div class="mt-64 text-center text-2xl font-bold">
              "COMPRENDRE ET APPLIQUER LA LOI DE FINANCES 2023"
            </div>

            <p className="date text-white">
              Issued on <span className="bold">March 15 2021</span>
            </p>
          </div>

          <svg
            width="99"
            height="139"
            viewBox="0 0 99 139"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H99V138.406L52.1955 118.324L0 138.406V0Z"
              fill="white"
            />
            <path
              d="M25.4912 83.2515C25.4912 79.4116 27.0222 75.7289 29.7474 73.0137C32.4727 70.2985 36.1689 68.7731 40.0229 68.7731C43.877 68.7731 47.5732 70.2985 50.2984 73.0137C53.0236 75.7289 54.5546 79.4116 54.5546 83.2515M40.0229 59.724C40.0229 55.8841 41.5539 52.2014 44.2791 49.4862C47.0044 46.7709 50.7006 45.2455 54.5546 45.2455C58.4087 45.2455 62.1049 46.7709 64.8301 49.4862C67.5553 52.2014 69.0863 55.8841 69.0863 59.724V83.2515"
              stroke="#0379FF"
              strokeWidth="10.6193"
            />
          </svg>
        </div>

        {/* FORM ADD CERTIFICAT */}

        <footer class="relative bg-blueGray-50 pt-8 pb-6 mt-48">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap items-center md:justify-between justify-center">
              <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                <div class="text-sm text-blueGray-500 font-semibold py-1 bg-white rounded-full">
                  CopyRight@{" "}
                  <a
                    href="https://www.creative-tim.com/product/notus-js"
                    class="text-blueGray-500 hover:text-gray-800"
                    target="_blank"
                  >
                    CINEF SAS
                  </a>{" "}
                  by{" "}
                  <a
                    href="/"
                    class="text-blueGray-500 hover:text-blueGray-800"
                    target="_blank"
                  >
                    {" "}
                    Cotonou - Bénin
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
