import React from "react";
import Header from "../Header";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Protedroute from "./Protedroute";
import axios from "axios";
import emailjs from "emailjs-com";

export default function Userdash() {
  const [darkToggle, setDarkToggle] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [fullname, setFullname] = useState("");
  const [structure, setStructure] = useState("");
  const [theme, setTheme] = useState("");
  const [periode, setPeriode] = useState("");
  const [typeformation, setTypeformation] = useState("");
  const [formdata, setFormData] = useState([]);
  const [consltdata, setConsltdata] = useState([]);
  const [intervenant, setIntervenant] = useState("");
  const [intervenant2, setIntervenant2] = useState("");
  const [titre, setTitre] = useState("");
  const [phone, setPhone] = useState("");
  const [reference, setReference] = useState("");
  const [titre2, setTitre2] = useState("");
  const [lieu, setLieu] = useState("");
  const [succesmsg, setSuccesmsg] = useState("");
  const [errormsg, setErrormsg] = useState("");

  const navigate = useNavigate();

  //const myref = setTimeout(setReference(ref), 10000);
  //console.log("réf", ref);

  //date
  const current = new Date();
  const ondate = current.toLocaleString();
  //const ondate = Date();
  //console.log(ondate);

  const location = useLocation();
  /*console.log(location);*/
  const formAPI = process.env.React_App_Uri_api + "formations";
  const FormerUrl = process.env.React_App_Uri_api + "formateurs";
  const CertificatUrl = process.env.React_App_Uri_api + "certificats";

  const ref = "cinef-" + (Math.random() + 1).toString(36).substring(7);
  const user = sessionStorage.getItem("UserName");
  /*console.log(user);*/

  /*CheckUserIdentity*/
  const checkUserprofile = () => {
    const userInfo = sessionStorage.getItem("UserName");
    if (userInfo === null) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  const getCertiData = () => {
    //GET LIST FORMATEUR

    axios
      .get(FormerUrl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      })
      .then((res) => {
        //console.log(res);
        //Storing users detail in state array object
        setConsltdata(res.data);
        //console.log(res.data);
      });

    //console.log(formdata)

    //GET LIST FORMATEUR

    //GET LIST FORMATION

    axios
      .get(formAPI, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      })
      .then((res) => {
        //Storing users detail in state array object
        //console.log(res);
        setFormData(res.data);
        // console.log(res.data);
      });

    //console.log(formdata)

    //LIST FORMATION
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      checkUserprofile();
      getCertiData();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  //Onsubmit

  const handleSubmit = async (event) => {
    // const ref = "cinef-" + (Math.random() + 1).toString(36).substring(7);
    //Prevent page reload
    event.preventDefault();
    var {
      theme,
      fullname,
      email,
      periode,
      structure,
      typeformation,
      intervenant,
      intervenant2,
      lieu,
      periode,
      titre,
      titre2,
      reference,
    } = document.forms[0];

    //Generate Code de Réf

    if (
      periode.value === "" ||
      fullname.value === "" ||
      structure.value === ""
    ) {
      setErrormsg("Ouuf, veuillez remplir tous les champs et rééssayer !");
      return;
    }

    const certificatData = {
      theme: theme.value,
      fullname: fullname.value,
      email: email.value,
      dateon: periode.value,
      structure: structure.value,
      typeformation: typeformation.value,
      reference: reference.value,
      intervenant: intervenant.value,
      intervenant2: intervenant2.value,
      titre: titre.value,
      titre2: titre2.value,
      lieu: lieu.value,
      phone: phone.value,
    };

    setTheme(certificatData.theme);
    setTypeformation(certificatData.typeformation);
    setIntervenant(certificatData.intervenant);
    setIntervenant2(certificatData.intervenant2);
    setLieu(certificatData.lieu);
    setReference(certificatData.reference);
    setTitre(certificatData.titre);
    setTitre2(certificatData.titre2);
    setFullname(certificatData.fullname);
    setStructure(certificatData.structure);
    setPeriode(certificatData.dateon);

    const CertifDataJson = JSON.stringify(certificatData);
    //console.log(CertifDataJson);
    axios
      .post(CertificatUrl, CertifDataJson, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      })
      .then(function (response) {
        //send Email
        //console.log(event.target.reference);

        //console.log(response);

        // code fragment
        if (response.data.newCertificat) {
          alert("L'attestation a été créée avec succès !");

          const data = {
            service_id: process.env.React_App_Email_service_id,
            template_id: process.env.React_App_Email_template_id,
            user_id: process.env.React_App_Email_user_id,
            template_params: {
              email: certificatData.email,
              fullname: certificatData.fullname,
              reference: certificatData.reference,
            },
          };

         //console.log(data); E-mail Sender Emailjs

          fetch("https://api.emailjs.com/api/v1.0/email/send", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(data),
          }).then(
            (result) => {
              //console.log(result.statusText);
              setSuccesmsg(result.statusText);
              //SEND WHATSAPP MSG

              var data = JSON.stringify({
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: phone,
                type: "template",
                template: {
                  name: "simple_notification",
                  language: {
                    code: "fr",
                  },
                  components: [
                    {
                      type: "header",
                      parameters: [
                        {
                          type: "image",
                          image: {
                            link: "https://www.cinef-bj.com/formations/wp-content/uploads/2020/03/logo_cinef-1.png",
                          },
                        },
                      ],
                    },
                    {
                      type: "body",
                      parameters: [
                        {
                          type: "text",
                          text: certificatData.fullname,
                        },
                        {
                          type: "text",
                          text: certificatData.reference,
                        },
                      ],
                    },
                  ],
                },
              });
                //const uri_whatsapp = process.env.React_App_WhatsApp_uri;
                //console.log(uri_whatsapp);
                
              var config = {
                method: "post",
                url: process.env.React_App_WhatsApp_uri,
                headers: {
                  Authorization: `Bearer ${process.env.React_App_WhatsApp_token}`,
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  //console.log(JSON.stringify(response.data));
                  const res = response.statusText;
                  if (res === "OK") {
                    setSuccesmsg(
                      "La notification whatsApp a été envoyée avec succès au" +
                        " " +
                        phone
                    );
                  }
                })
                .catch(function (error) {
                  //console.log(error);
                });

              //SEND WHATSAPP MSG
            },
            (error) => {
              //console.log(error.statusText);
              setErrormsg(error.statusText);
            }
          );

          // e.target.reset();
          //send Email
          // console.log(response);
          setSuccesmsg(response.data.message);
          //const statusCode = response.status;
          // console.log(statusCode);
        }
        if (!response.data.newCertificat) {
          alert("Attention,  Cette attestation existe déjà dans la base !");
        }
        {
          /*  
    
       */
        }
      })
      .catch((e) => {
        //const errorServer= console.log(e.response.status);
        //console.log(e);
        setErrormsg(
          "Oouf, une erreur est survenue ! Veuillez signaler l'erreur à l'administrateur. "
        );
      });
  };

  return (
    <>
      <Protedroute />
      <div class={`  ${darkToggle && "bg-gray-900"}`}>
        <aside class=" ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r bg-{dark}  transition duration-300 md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]  ">
          <div>
            <div class="float-right relative">
              <a href="#" title="TABLEAU DE BORD">
                <img
                  src=".././images/CARRE_CINEF.jpeg"
                  class="w-8 rounded-full "
                  alt="tailus logo"
                ></img>
                Connecté
              </a>
            </div>

            <div class=" text-center ">
              <img
                src=".././images/fotor-03d1a91a0cec4542927f53c87e0599f6.webp"
                alt=""
                class="w-10 h-10 m-auto rounded-full object-cover lg:w-28 lg:h-28"
              ></img>
              <h5 class="hidden mt-4 text-sm font-semibold text-gray-600 lg:block">
                Marie-Ange {user}
              </h5>
              <span class="hidden text-gray-400 lg:block">
                ASSISTANTE DE DIRECTION
              </span>
            </div>
            <ul class="space-y-2 tracking-wide mt-8">
              <li>
                <a
                  href="#"
                  aria-label="dashboard"
                  class="relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white bg-green-700 from-sky-600 to-cyan-400"
                >
                  <svg class="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                      class="fill-current text-cyan-400 dark:fill-slate-600"
                    ></path>
                    <path
                      d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                      class="fill-current text-cyan-200 group-hover:text-cyan-300"
                    ></path>
                    <path
                      d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                      class="fill-current group-hover:text-sky-300"
                    ></path>
                  </svg>
                  <span class="-mr-1 font-medium">DASHBOARD</span>
                </a>
              </li>
              <li>
                <a
                  href="./userdash"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700 border-b-2 border-green-500">
                    Attestation +
                  </span>
                </a>
              </li>

              <li>
                <a
                  href="./addformation"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Thème +</span>
                </a>
              </li>
              <li>
                <a
                  href="./addformateur"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Consultant</span>
                </a>
              </li>
              <li>
                <a
                  href="./allattestations"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M15 0H9v7c0 .55.45 1 1 1h1v1h1V8h3c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-4 7h-1V6h1v1zm4 0h-3V6h3v1zm0-2h-4V1h4v4zM4 5H3V4h1v1zm0-2H3V2h1v1zM2 1h6V0H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h2v2l1.5-1.5L6 16v-2h5c.55 0 1-.45 1-1v-3H2V1zm9 10v2H6v-1H3v1H1v-2h10zM3 8h1v1H3V8zm1-1H3V6h1v1z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Attestations</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
            <button
              class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
              onClick={logout}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <span class="group-hover:text-gray-700">Déconnexion</span>
            </button>
          </div>
        </aside>
        {/* KPIs */}
        <div class="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
          <div class="sticky z-10 top-0 h-16 border-b bg-white lg:py-2.5">
            <div class="px-6 flex items-center justify-between space-x-4 2xl:container">
              <h5 hidden class="text-2xl text-gray-600 font-bold lg:block">
                TABLEAU DE BORD
              </h5>
              <button class="w-12 h-16 -mr-2 border-r lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 my-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>

              {/* Bar seach */}
              <div class="flex space-x-4">
                <div hidden class="md:block">
                  <div class="relative flex items-center text-gray-400  focus-within:text-cyan-400"></div>
                </div>

                <button
                  onClick={() => setDarkToggle(!darkToggle)}
                  aria-label="chat"
                  class="w-10 h-10 rounded-xl border  bg-gray-400 focus:bg-gray-900 active:bg-gray-400"
                >
                  <svg
                    class="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="white"
                      stroke-dasharray="2"
                      stroke-dashoffset="2"
                      stroke-linecap="round"
                      stroke-width="2"
                    >
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.6s"
                          dur="0.2s"
                          values="M12 19v1M19 12h1M12 5v-1M5 12h-1;M12 21v1M21 12h1M12 3v-1M3 12h-1"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.6s"
                          dur="0.2s"
                          values="2;0"
                        />
                      </path>
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.9s"
                          dur="0.2s"
                          values="M17 17l0.5 0.5M17 7l0.5 -0.5M7 7l-0.5 -0.5M7 17l-0.5 0.5;M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.9s"
                          dur="1.2s"
                          values="2;0"
                        />
                      </path>
                      <animateTransform
                        attributeName="transform"
                        dur="30s"
                        repeatCount="indefinite"
                        type="rotate"
                        values="0 12 12;360 12 12"
                      />
                    </g>
                    <mask id="svgIDa">
                      <circle cx="12" cy="12" r="12" fill="#fff" />
                      <circle cx="18" cy="6" r="12" fill="#fff">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="12;3"
                        />
                      </circle>
                      <circle cx="18" cy="6" r="10">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="10;1"
                        />
                      </circle>
                    </mask>
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      fill="gray"
                      mask="url(#svgIDa)"
                    >
                      <animate
                        fill="freeze"
                        attributeName="r"
                        dur="0.4s"
                        values="10;6"
                      />
                    </circle>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Body I*/}
          <p class="text-gray-500 text-xl font-bold text-center mt-8 italic">
            Veuillez remplir ces champs pour générer une Attestation de
            formation.
          </p>

          <p class="text-green-500 text-xl text-center mt-4 ">{succesmsg}</p>
          <p class="text-red-500 text-xl text-center mt-4 ">{errormsg}</p>
          <form
            method="post"
            name="userCertificate"
            onSubmit={handleSubmit}
            class="w-full max-lg p-4 mt-4 border-2 mx-auto bg-gray-200"
            id="myForm"
          >
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Thème de la formation:
                </label>
                <select
                  name="theme"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Loi de finances 2023"
                  required
                >
                  {formdata.map((option) => (
                    <option value={option.theme}>{option.theme}</option>
                  ))}
                </select>

                {/*<p class="text-red-500 text-xs italic">Veuillez remplir ce champ.</p>*/}
              </div>
              <div class="w-full md:w-1/2 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Nom et Prénom(s) de l'impétrant:
                </label>
                <input
                  name="fullname"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="JOSEPHATE Samuel "
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  E-mail de l'impétrant:
                </label>
                <input
                  name="email"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="email"
                  placeholder="exemple@exemple.com"
                  required
                />

                {/*<p class="text-red-500 text-xs italic">
                     Veuillez remplir ce champ.
                   </p>*/}
              </div>
              <div class="w-full md:w-1/3 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Structure:
                </label>
                <input
                  name="structure"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="GIZ RD CONGO "
                  required
                />
              </div>
              {/* Code Réf */}

              <div class="w-full md:w-1/3 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Code de référence:
                </label>
                <input
                  disabled
                  name="reference"
                  value={ref}
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  required
                />
              </div>
              {/* Code Réf */}
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Type de formation:
                </label>
                <select
                  name="typeformation"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Type de formation"
                >
                  <option value="Présentiel">Présentiel</option>
                  <option value="Classe virtuelle">Classe virtuelle</option>
                  <option value="E-learning">E-learning</option>
                </select>

                {/* <p class="text-red-500 text-xs italic">
                     Veuillez remplir ce champ.
                  </p>*/}
              </div>
              <div class="w-full md:w-1/3 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Période/Durée de la formation:
                </label>
                <input
                  name="periode"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Du 02/01/2023 au 06/01/2023 (05jrs)  "
                  required
                />
              </div>
              <div class="w-full md:w-1/3 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Numéro WhatsApp de l'Impétrant
                </label>
                <input
                  name="phone"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="number"
                  placeholder="22901020304  "
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Formateur 1 :
                </label>
                <select
                  name="intervenant"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Loi de finances 2023"
                  required
                >
                  {consltdata.map((option) => (
                    <option value={option.fullname}>{option.fullname}</option>
                  ))}
                </select>

                {/* <p class="text-red-500 text-xs italic">
                     Veuillez remplir ce champ.
                  </p>*/}
              </div>
              <div class="w-full md:w-1/3 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Formateur 2 :
                </label>
                <select
                  name="intervenant2"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Loi de finances 2023"
                  required
                >
                  {consltdata.map((option) => (
                    <option value={option.fullname}>{option.fullname}</option>
                  ))}
                </select>
              </div>

              <div class="w-full md:w-1/3 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Lieu de la formation :
                </label>
                <select
                  name="lieu"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Loi de finances 2023"
                  required
                >
                  {formdata.map((option) => (
                    <option value={option.lieu}>{option.lieu}</option>
                  ))}
                </select>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Titre formateur 1 :
                </label>
                <select
                  name="titre"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="fiscaliste"
                  required
                >
                  {consltdata.map((option) => (
                    <option value={option.title}>{option.title}</option>
                  ))}
                </select>

                {/* <p class="text-red-500 text-xs italic">
                     Veuillez remplir ce champ.
                  </p>*/}
              </div>
              <div class="w-full md:w-1/2 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Titre formateur 2 :
                </label>
                <select
                  name="titre2"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Andragogue"
                  required
                >
                  {consltdata.map((option) => (
                    <option value={option.title}>{option.title}</option>
                  ))}
                </select>
              </div>
            </div>

            <button
              type="submit"
              class="flex-shrink-0 w-20 text-center font-bold bg-green-700 hover:bg-gray-700 border-green-700 hover:border-gray-700 text-sm border-4 text-white py-1 px-2 rounded"
              onClick={() => setShowModal(true)}
            >
              {" "}
              Valider
            </button>
          </form>

          {/* Body II*/}
        </div>

        {/* MODAL*/}

        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      VALIDATION DE L'ATTESTATION
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div class="w-full p-4 text-center bg-white border  rounded-lg shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                      <div class="border-8 p-4 border-green-700 ">
                        <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-64">
                          <img
                            src=".././images/logo_cinef_ok.png"
                            class="w-48 justify-center"
                            alt="Logo CINEF"
                          />
                          <img
                            src="https://png.monster/wp-content/uploads/2022/06/png.monster-219.png"
                            class="w-16 justify-center"
                            alt="Code Qr"
                          />
                        </div>
                        <p class="text-right px-4 text-red-600 ">
                          réf: {reference}
                        </p>
                        <h5 class="mb-2 text-2xl font-bold mt-4 text-green-700 dark:text-white">
                          ATTESTATION DE FORMATION
                        </h5>
                        <p class="mb-4 text-base font-bold text-gray-500 sm:text-lg dark:text-gray-400">
                          Nous attestons que:
                        </p>
                        <p class="mb-0 text-base text-xl font-bold text-green-900 sm:text-lg dark:text-gray-400">
                          {fullname}
                        </p>
                        <p class="mb-0 text-base text-sm font-bold text-red-600 sm:text-lg dark:text-gray-400">
                          {structure}
                        </p>
                        <p class="mb-5 text-base text-sm font-bold text-gray-400 sm:text-sm dark:text-gray-400">
                          a suivi avec succès la formation
                        </p>
                        <p class="mb-0 text-base text-2xl font-bold text-red-600 sm:text-lg dark:text-gray-400">
                          {theme}
                        </p>
                        <p class="mb-5 text-base text-sm font-bold text-gray-400 sm:text-sm dark:text-gray-400">
                          {periode} | {lieu}| {typeformation}
                        </p>

                        <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-64">
                          <a
                            href="#"
                            class="w-full sm:w-auto bg-wite hover:bg-white-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-gray-500 rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                          >
                            <div class="text-left">
                              <div class="mb-1 text-xs">Instructeur(s)</div>
                              <div class="-mt-1 font-sans text-sm font-semibold">
                                <p>
                                  {" "}
                                  {intervenant} : {titre}
                                </p>
                                <p>
                                  {intervenant2} : {titre2}{" "}
                                </p>
                              </div>
                            </div>
                          </a>
                          <img
                            src=".././images/Cahet-sign.png"
                            class="w-64 "
                            alt="CINEF SIGNATURE"
                          />
                        </div>
                        <p class="mb-2 text-base text-sm font-bold text-gray-400 sm:text-sm dark:text-gray-400">
                          {ondate}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      +
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

        {/* MODAL*/}
        <footer class="relative bg-blueGray-50 pt-8 pb-6 mt-48">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap items-center md:justify-between justify-center">
              <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                <div class="text-sm text-blueGray-500 font-semibold py-1 bg-white rounded-full">
                  CopyRight@{" "}
                  <a
                    href="https://www.creative-tim.com/product/notus-js"
                    class="text-blueGray-500 hover:text-gray-800"
                    target="_blank"
                  >
                    CINEF SAS
                  </a>{" "}
                  by{" "}
                  <a
                    href="/"
                    class="text-blueGray-500 hover:text-blueGray-800"
                    target="_blank"
                  >
                    {" "}
                    Cotonou - Bénin
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
