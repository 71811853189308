import React from "react";
import Header from "../Header";
import { useState, useEffect } from "react";
import { useNavigate,useLocation,Link } from "react-router-dom";
import Protedroute from "./Protedroute";
import axios from "axios";


export default function Userdash() {
  const [darkToggle, setDarkToggle] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [succesmsg, setSuccesmsg] = React.useState("");
  const [errormsg, setErrormsg] = React.useState("");
  const [lieu, setLieu] = useState("");
  const [theme, setTheme] = useState("");
  const [periode, setPeriode] = useState("");

  
  const navigate = useNavigate();
 
  
const formAPI = process.env.React_App_Uri_api + "formations";


  const user = sessionStorage.getItem("UserName");
  /*console.log(user);*/

  /*CheckUserIdentity*/
  const checkUserprofile = () => {
    const userInfo = sessionStorage.getItem("UserName");
    if (userInfo === null) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
  };
  useEffect(() => {
    checkUserprofile();
  });

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");

     
  };

  //Onsubmit form

  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();
    var { theme,  periode, lieu, code } = document.forms[0];

    const themeData = {
      theme: theme.value,
      periode: periode.value,
      lieu: lieu.value,
      
    };
   const themeDataJson= JSON.stringify(themeData);
   //const accessToken = localStorage.getItem("user-token");
   //console.log(accessToken);

axios
  .post(formAPI, themeDataJson, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("user-token")}`,
    },
  })
  .then(function (response) {
    // console.log(response);
    setSuccesmsg(response.data.message);
    //const statusCode = response.status;
    // console.log(statusCode);
  })
  .catch((e) => {
    //const errorServer= console.log(e.response.status);

    setErrormsg(
      "Oouf, une erreur est survenue ! Veuillez signaler l'erreur à l'administrateur. "
    );
  });
   


    
  };


  return (
    <>
      <Protedroute />
      <div class={`  ${darkToggle && "bg-gray-900"}`}>
        <aside class=" ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r bg-{dark}  transition duration-300 md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]  ">
          <div>
            <div class="float-right relative">
              <a href="#" title="TABLEAU DE BORD">
                <img
                  src=".././images/CARRE_CINEF.jpeg"
                  class="w-8 rounded-full "
                  alt="tailus logo"
                ></img>
                Connecté
              </a>
            </div>

            <div class="text-center ">
              <img
                src=".././images/fotor-03d1a91a0cec4542927f53c87e0599f6.webp"
                alt=""
                class="w-10 h-10 m-auto rounded-full object-cover lg:w-28 lg:h-28"
              ></img>
              <h5 class="hidden mt-4 text-sm font-semibold text-gray-600 lg:block">
                Marie-Ange {user}
              </h5>
              <span class="hidden text-gray-400 lg:block">
                ASSISTANTE DE DIRECTION
              </span>
            </div>

            <ul class="space-y-2 tracking-wide mt-8">
              <li>
                <a
                  href="#"
                  aria-label="dashboard"
                  class="relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white bg-green-700 from-sky-600 to-cyan-400"
                >
                  <svg class="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                      class="fill-current text-cyan-400 dark:fill-slate-600"
                    ></path>
                    <path
                      d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                      class="fill-current text-cyan-200 group-hover:text-cyan-300"
                    ></path>
                    <path
                      d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                      class="fill-current group-hover:text-sky-300"
                    ></path>
                  </svg>
                  <span class="-mr-1 font-medium">DASHBOARD</span>
                </a>
              </li>
              <li>
                <a
                  href="./userdash"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Attestation +</span>
                </a>
              </li>
              <li>
                <a
                  href="./addformation"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700 border-b-2 border-green-500 ">
                    Thème +
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="./addformateur"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
                    />
                    <path
                      fill="currentColor"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Consultant</span>
                </a>
              </li>
              <li>
                <a
                  href="./allattestations"
                  class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M15 0H9v7c0 .55.45 1 1 1h1v1h1V8h3c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-4 7h-1V6h1v1zm4 0h-3V6h3v1zm0-2h-4V1h4v4zM4 5H3V4h1v1zm0-2H3V2h1v1zM2 1h6V0H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h2v2l1.5-1.5L6 16v-2h5c.55 0 1-.45 1-1v-3H2V1zm9 10v2H6v-1H3v1H1v-2h10zM3 8h1v1H3V8zm1-1H3V6h1v1z"
                    />
                  </svg>
                  <span class="group-hover:text-gray-700">Attestations</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
            <button
              class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group"
              onClick={logout}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <span class="group-hover:text-gray-700">Déconnexion</span>
            </button>
          </div>
        </aside>
        {/* KPIs */}
        <div class="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
          <div class="sticky z-10 top-0 h-16 border-b bg-white lg:py-2.5">
            <div class="px-6 flex items-center justify-between space-x-4 2xl:container">
              <h5 hidden class="text-2xl text-gray-600 font-bold lg:block">
                TABLEAU DE BORD
              </h5>
              <button class="w-12 h-16 -mr-2 border-r lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 my-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>

              {/* Bar seach */}
              <div class="flex space-x-4">
                <div hidden class="md:block">
                  <div class="relative flex items-center text-gray-400  focus-within:text-cyan-400"></div>
                </div>

                <button
                  onClick={() => setDarkToggle(!darkToggle)}
                  aria-label="chat"
                  class="w-10 h-10 rounded-xl border  bg-gray-400 focus:bg-gray-900 active:bg-gray-400"
                >
                  <svg
                    class="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="white"
                      stroke-dasharray="2"
                      stroke-dashoffset="2"
                      stroke-linecap="round"
                      stroke-width="2"
                    >
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.6s"
                          dur="0.2s"
                          values="M12 19v1M19 12h1M12 5v-1M5 12h-1;M12 21v1M21 12h1M12 3v-1M3 12h-1"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.6s"
                          dur="0.2s"
                          values="2;0"
                        />
                      </path>
                      <path d="M0 0">
                        <animate
                          fill="freeze"
                          attributeName="d"
                          begin="0.9s"
                          dur="0.2s"
                          values="M17 17l0.5 0.5M17 7l0.5 -0.5M7 7l-0.5 -0.5M7 17l-0.5 0.5;M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.9s"
                          dur="1.2s"
                          values="2;0"
                        />
                      </path>
                      <animateTransform
                        attributeName="transform"
                        dur="30s"
                        repeatCount="indefinite"
                        type="rotate"
                        values="0 12 12;360 12 12"
                      />
                    </g>
                    <mask id="svgIDa">
                      <circle cx="12" cy="12" r="12" fill="#fff" />
                      <circle cx="18" cy="6" r="12" fill="#fff">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="12;3"
                        />
                      </circle>
                      <circle cx="18" cy="6" r="10">
                        <animate
                          fill="freeze"
                          attributeName="cx"
                          dur="0.4s"
                          values="18;22"
                        />
                        <animate
                          fill="freeze"
                          attributeName="cy"
                          dur="0.4s"
                          values="6;2"
                        />
                        <animate
                          fill="freeze"
                          attributeName="r"
                          dur="0.4s"
                          values="10;1"
                        />
                      </circle>
                    </mask>
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      fill="gray"
                      mask="url(#svgIDa)"
                    >
                      <animate
                        fill="freeze"
                        attributeName="r"
                        dur="0.4s"
                        values="10;6"
                      />
                    </circle>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Body I*/}

          <p class="text-gray-500 font-bold text-xl text-center mt-8 italic">
            Veuillez remplir ces champs pour ajouter un thème de formation
          </p>

          <p class="text-green-500 text-xl text-center mt-4 ">{succesmsg}</p>
          <p class="text-red-500 text-xl text-center mt-4 ">{errormsg}</p>

          <a
            href="./allformations"
            class="bg-transparent mx-4 hover:bg-green-700 text-red-500 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
          >
            Voir toutes les formations
          </a>

          <form
            method="post"
            name="userCertificate"
            onSubmit={handleSubmit}
            class="w-full max-lg p-4 mt-4 border-2 mx-auto bg-gray-200 "
          >
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Thème de la formation:
                </label>
                <input
                  name="theme"
                  class="appearance-none block  uppercase w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Loi de finances 2023"
                  required
                />

                {/*<p class="text-red-500 text-xs italic">Veuillez remplir ce champ.</p>*/}
              </div>
              <div class="w-full md:w-1/2 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Lieu de la formation :
                </label>
                <input
                  name="lieu"
                  onChange={(e) => setLieu(e.target.value)}
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Cotonou, Bénin "
                  required
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              {/*     <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Code la formation :
                </label>
                <input
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="SRH34"
                />

                 <p class="text-red-500 text-xs italic">
                     Veuillez remplir ce champ.
                  </p>
              </div>*/}
              <div class="w-full md:w-1/1 px-3 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Periode de la formation:
                </label>
                <input
                  name="periode"
                  onChange={(e) => setPeriode(e.target.value)}
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Du 02/01/2023 au 06/01/2023  "
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              class="flex-shrink-0 w-20 text-center font-bold bg-green-700 hover:bg-gray-700 border-green-700 hover:border-gray-700 text-sm border-4 text-white py-1 px-2 rounded"
            >
              {" "}
              Valider
            </button>
          </form>
          {/* Body II*/}
        </div>

        {/* MODAL*/}

        {/* MODAL*/}
        <footer class="relative bg-blueGray-50 pt-8 pb-6 mt-48">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap items-center md:justify-between justify-center">
              <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                <div class="text-sm text-blueGray-500 font-semibold py-1 bg-white rounded-full">
                  CopyRight@{" "}
                  <a
                    href="https://www.creative-tim.com/product/notus-js"
                    class="text-blueGray-500 hover:text-gray-800"
                    target="_blank"
                  >
                    CINEF SAS
                  </a>{" "}
                  by{" "}
                  <a
                    href="/"
                    class="text-blueGray-500 hover:text-blueGray-800"
                    target="_blank"
                  >
                    {" "}
                    Cotonou - Bénin
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
