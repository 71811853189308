import TypeWriterEffect from 'react-typewriter-effect';
import React, {Text, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function App() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [certificats, setCertificats] = useState([]);
  const [errormsg, setErrormsg] = React.useState("");
  const [loading, setLoading] = useState(false);

  //Function onclick loading button

  const handleClick = () => {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  //Onsubmit form
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();
    var { reference } = document.forms[0];

    const codeData = {
      reference: reference.value,
    };
    const certiDataJson = JSON.stringify(codeData);
    //const accessToken = localStorage.getItem("user-token");
    //console.log(certiDataJson);

    axios
      .get(
        process.env.React_App_Uri_api + "certificats/" + reference.value,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            // "params": { certiDataJson },
            // Authorization: `Bearer ${localStorage.getItem("user-token")}`,
          },
        }
      )
      .then(function (response) {
        //console.log(response.data);
        const dataCertif = response.data;

        //console.log(certificats)
        if (response.data === "") {
          alert(
            "Désolé, cette attestation n'est pas dans notre régistre ou le code de référence n'est pas correct !"
          );
          setErrormsg(
            "Désolé, cette attestation n'est pas dans notre régistre ou le code de référence n'est pas correct ! "
          );
        }
        if (response.data.reference) {
          navigate("/confirmationpage", {
            state: {
              fullname: dataCertif.fullname,
              reference: dataCertif.reference,
              theme: dataCertif.theme,
              dateon: dataCertif.dateon,
            },
          });
        }
        //setSuccesmsg(response.data.message);
        //const statusCode = response.status;
        // console.log(statusCode);
      })
      .catch((e) => {
        //const errorServer= console.log(e.response.status);

        setErrormsg(
          "Oouuf, une erreur s'est produite.Veuillez patienter et rééssayer plus  ! "
        );
      });
  };

  return (
    <>
      <div class="bg-white dark:bg-gray-800 flex items-center">
        <div class="mx-auto bg-white px-6 flex flex-col justify-between items-center ">
          <div class="flex flex-col bg-white">
            <a
              class="mt-4 mx-auto"
              href="https://cinef-bj.com/formations"
              target="_blank"
            >
              <img
                src=".././images/logo_cinef_ok.png"
                class="w-48"
                alt="Flowbite Logo"
              />
            </a>
            <h1 class="font-bold w-full uppercase text-center mt-8 text-xl sm:text-5xl dark:text-white text-green-900">
              VERIFICATION DE L'AUTHENTICITE DE L' ATTESTATION DE CINEF
            </h1>

            <h2 class="font-light max-w-3xl mx-auto  text-xl dark:text-white text-gray-700 text-center py-4 ">
              Nos attestations/certificats sont digitalisé(e)s et offrent une
              facilité d'utilisation et de vérification n'importe ou et
              n'importe quand.
            </h2>
          </div>

          {/*ATTESTATION RENDER*/}

          <div className="relative w-full h-full  md:h-auto">
            {/*content*/}
            <div className="flex flex-col w-full bg-green-900 ">
              {/*header*/}

              {/*body*/}

              {/*footer*/}
              <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                <aside
                  id="revue-embed"
                  class="p-4 my-8 bg-white border border-gray-200 rounded-lg shadow-md  sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700"
                  aria-label="Subscribe to the Flowbite newsletter"
                >
                  <h3 class="mb-3 text-xl font-bold text-gray-900 dark:text-white">
                    Confirmez l'authenticité de l'attestation reçu !
                  </h3>
                  <p class="text-red-500 text-xl text-center mt-2 ">
                    {errormsg}
                  </p>
                  <p class="mb-5 text-sm font-medium text-gray-500 dark:text-gray-300">
                    Chaque attestation est unique et personnel. Avec le code de
                    référence inscrit sur la version papier, vous pouvez
                    confirmer oui ou non de l'originalité du document en face de
                    vous.
                  </p>
                  <form
                    method="post"
                    id="revue-form"
                    name="confirmation"
                    onSubmit={handleSubmit}
                  >
                    <div class="flex items-end mb-3">
                      <div class="relative w-full mr-3 revue-form-group">
                        <label
                          for="member_email"
                          class="hidden block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Code de vérification
                        </label>
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="gray"
                              d="M15 21h-2v-2h2v2zm-2-7h-2v5h2v-5zm8-2h-2v4h2v-4zm-2-2h-2v2h2v-2zM7 12H5v2h2v-2zm-2-2H3v2h2v-2zm7-5h2V3h-2v2zm-7.5-.5v3h3v-3h-3zM9 9H3V3h6v6zm-4.5 7.5v3h3v-3h-3zM9 21H3v-6h6v6zm7.5-16.5v3h3v-3h-3zM21 9h-6V3h6v6zm-2 10v-3h-4v2h2v3h4v-2h-2zm-2-7h-4v2h4v-2zm-4-2H7v2h2v2h2v-2h2v-2zm1-1V7h-2V5h-2v4h4zM6.75 5.25h-1.5v1.5h1.5v-1.5zm0 12h-1.5v1.5h1.5v-1.5zm12-12h-1.5v1.5h1.5v-1.5z"
                            />
                          </svg>
                        </div>
                        <input
                          class="revue-form-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-900 focus:border-green-900 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-900 dark:focus:border-green-900"
                          placeholder="cinef-e4eg"
                          type="text"
                          name="reference"
                          id="reference"
                          required
                        />
                      </div>
                      <div class="revue-form-actions">
                        <button
                          type="submit"
                          value="Login"
                          className={`bg-green-700 hover:bg-gray-700 text-white py-2 px-4 border border-white rounded ${
                            loading ? "cursor-not-allowed opacity-25" : ""
                          }`}
                          onClick={handleClick}
                          enable={loading}
                        >
                          {loading ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 animate-spin mx-auto"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                              />
                            </svg>
                          ) : (
                            "Ok"
                          )}
                        </button>{" "}
                      </div>
                    </div>
                    <div class="text-sm font-medium text-gray-500 revue-form-footer dark:text-gray-300">
                      Nous vous prions de signaler toute contrefaçon constaté en
                      cas de rejet du code de référence par le système{" "}
                      <a
                        rel="nofollow"
                        href="#"
                        class="text-green-600 hover:underline font-bold dark:text-green-900"
                      >
                        +229 63 18 60 60 / 97 08 46 01
                      </a>{" "}
                      ou parE-mail:{" "}
                      <a
                        rel="nofollow"
                        class="text-green-600 hover:underline font-bold dark:text-green-900"
                        href="#"
                      >
                        info@cinef-bj.com
                      </a>
                    </div>
                  </form>
                </aside>
              </div>
            </div>
          </div>
        </div>

        {/*ATTESTATION RENDER*/}
      </div>
    </>
  );
}
