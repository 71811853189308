import React from "react";
import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";



export default function Dashboard() {
  const [darkToggle, setDarkToggle] = React.useState(false);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userhistory, setUserHistory] = useState([]);

     

    //localStorage.setItem("trackUser", url);


  const checkUserToken = () => {
    const userToken = localStorage.getItem("user-token");
    //const userTracking = localStorage.getItem("trackUser");
//setUserHistory(window.location.href);
//console.log(userhistory);

    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      navigate("/login");
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
}
