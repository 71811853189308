import React from "react";
import Header from "./Header";
import { useState } from "react";
//import axios from "axios";
//import emailjs from "emailjs-com";

export default function Contactform() {
  const [succesmsg, setSuccesmsg] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [loading, setLoading] = useState(false);

  //Validation Email
  var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  function isValidEmail(email) {
    return mailformat.test(email);
  }

  //Function onclick loading button

  const handleClick = () => {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };


  
  const handleSubmit = async (event) => {
    // const ref = "cinef-" + (Math.random() + 1).toString(36).substring(7);
    //Prevent page reload

    event.preventDefault();
    var { fullname, email, phone, message, objet } = document.forms[0];

    //Validation Email
    if (!isValidEmail(email.value)) {
      setErrormsg("Votre Email est invalide ⚠️!");
      return false;
    }

    if (
      email.value === "" ||
      fullname.value === "" ||
      phone.value === "" ||
      message.value === "" ||
      objet.value === ""
    ) {
      setErrormsg("Ouuf, veuillez remplir tous les champs et rééssayer !");
      return;
    } 
    
    if (email.value !== "") {
      if (!isValidEmail(email.value)) {
        setErrormsg("Votre Email est invalide ⚠️!");
        return;
      }
    }

    const formData = {
      email: email.value,
      fullname: fullname.value,
      phone: phone.value,
      message: message.value,
      objet: objet.value,
    };

    //send Email
    //console.log(event.target.reference);

    //console.log(response);

    // code fragment
    if (
      formData.email !== "" &&
      formData.fullname !== "" &&
      formData.phone !== ""
    ) {
      const data = {
        service_id: "service_o1p553a",
        template_id: "template_9rnvn9q",
        user_id: "Qqjp_IzuZla_CSO1k",
        template_params: {
          email: formData.email,
          fullname: formData.fullname,
          phone: formData.phone,
          message: formData.message,
          objet: formData.objet,
        },
      };

      fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((result) => {
          //console.log(result);
          //console.log(result.status);
          const res = result.status;
          alert("Votre message a été envoyé avec Success !");
          if (res === 200) {
            let input1 = document.getElementById("fullname");
            input1.value = "";

            let input2 = document.getElementById("email");
            input2.value = "";

            let input3 = document.getElementById("tel");
            input3.value = "";

            let input4 = document.getElementById("objet");
            input4.value = "";

            let input5 = document.getElementById("message");
            input5.value = "";

            setSuccesmsg(
              "Votre message a été envoyé avec succès ! Nous  vous reconctacterons dans les 24H. Merci"
            );
          }

          if (!res) {
            setErrormsg(
              "Ouuf ! erreur  d'envoi de votre message. Merci de rééssayer !"
            );
          }
        })
        .catch((error) => {
          setErrormsg(
            "Ouuf ! erreur  d'envoi de votre message. Veuillez patienter et rééssayer plustard ou envoyez-nous un mails à l'adresse : info@cinef-bj.com"
          );
          //console.log(error.statusText);
        });
    }
  };

  return (
    <>
      <Header />

      <div class="relative flex items-top justify-center min-h-screen bg-gray-600 dark:bg-gray-900 sm:items-center sm:pt-0">
        <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
          <div class="mt-2 overflow-hidden">
            <div class="grid grid-cols-1 md:grid-cols-2">
              <div class="p-6 mr-2 bg-gray-100 dark:bg-gray-800 sm:rounded-lg">
                <h1 class="text-4xl sm:text-5xl text-green-700 dark:text-white font-extrabold tracking-tight">
                  Contactez-nous !
                </h1>
                <p class="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-gray-400 mt-2">
                  Remplissez le formulaire pour entamer une conversation avec
                  nous
                </p>

                <div class="flex items-center mt-8 text-gray-600 dark:text-gray-400">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    class="w-8 h-8 text-gray-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <div class="ml-4 text-md tracking-wide font-semibold w-40">
                    Ste Rita, Cotonou, Bénin 03BP3620
                  </div>
                </div>

                <div class="flex items-center mt-4 text-gray-600 dark:text-gray-400">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    class="w-8 h-8 text-gray-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <div class="ml-4 text-md tracking-wide font-semibold w-40">
                    +229 63 18 60 60
                  </div>
                </div>

                <div class="flex items-center mt-2 text-gray-600 dark:text-gray-400">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    class="w-8 h-8 text-gray-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <div class="ml-4 text-md tracking-wide font-semibold w-40">
                    info@cinef-bj.com
                  </div>
                </div>
              </div>

              <form
                class="p-6 flex flex-col justify-center"
                onSubmit={handleSubmit}
              >
                <p class="text-green-400 text-xl text-center mt-4 ">
                  {succesmsg}
                </p>
                <p class="text-red-500 text-xl text-center mt-4 ">{errormsg}</p>

                <div class="flex flex-col">
                  <label for="name" class="hidden">
                    Nom et Prénom(s)
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    placeholder="Nom et Prénom(s)"
                    id="fullname"
                    class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  ></input>
                </div>

                <div class="flex flex-col mt-2">
                  <label for="email" class="hidden">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  required></input>
                </div>

                <div class="flex flex-col mt-2">
                  <label for="tel" class="hidden">
                    Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="tel"
                    placeholder="+22901020304 "
                    class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  ></input>
                </div>
                <div class="flex flex-col mt-2">
                  <label for="tel" class="hidden">
                    Objet du message
                  </label>
                  <input
                    type="text"
                    name="objet"
                    id="objet"
                    placeholder="Une formation nous interesse "
                    class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  ></input>
                </div>

                <div class="flex flex-col mt-2">
                  <label for="message" class="hidden">
                    Message
                  </label>
                  <textarea
                    type="text"
                    name="message"
                    id="message"
                    placeholder="Message"
                    class="w-100 mt-2 py-3 px-3 mb-2  rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  ></textarea>
                </div>

                <button
                  className={`bg-green-700 hover:bg-gray-700 text-white py-2 px-4 border border-white rounded ${
                    loading ? "cursor-not-allowed opacity-25" : ""
                  }`}
                  onClick={handleClick}
                  enable={loading}
                >
                  {loading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 animate-spin mx-auto"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  ) : (
                    "Envoyer"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="text-sm text-blueGray-500 font-semibold py-1 text-center">
        &copy; CopyRight@{" "}
        <a
          href="https://www.creative-tim.com/product/notus-js"
          class="text-blueGray-500 hover:text-gray-800"
          target="_blank"
        >
          CINEF SAS
        </a>{" "}
        by{" "}
        <a
          href="/"
          class="text-blueGray-500 hover:text-blueGray-800"
          target="_blank"
        >
          {" "}
          Cotonou - Bénin
        </a>
        .
      </div>
    </>
  );
}
